import loader from "../images/loader.gif"

export default function PageLoader(){
    return (
        <div className="columns is-centered is-mobile">
            <div className="column is-centered is-4">
                <img src={loader} />
            </div>
        </div> 
    )
}