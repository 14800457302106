export default {
    notFoundText: 'De pagina die u probeert te openen bestaat niet.',
    login: 'Inloggen',
    emailAddress: 'E-mailadres',
    password: 'Wachtwoord',
    forgotPassword: 'Wachtwoord vergeten',
    survey: 'Vragenlijst',
    surveys: 'Vragenlijsten',
    active: 'Actief',
    inActive: 'Inactief',
    archive: 'Archief',
    all: 'Alle',
    ready: 'Gereed',
    concept: 'Concept',
    search: 'Zoeken',
    createSurvey: 'Vragenlijst maken',
    createCampaign: 'Campagne maken',
    forgotPasswordInformation: 'Vul uw gebruikersnaam (e-mailadres) in en wij sturen uw een link om uw wachtwoord te resetten',
    backToLogin: 'Terug naar inloggen',
    logOut: 'Uitloggen',
    send: 'Verzenden',
    youGotMail: 'U hebt mail!',
    youGotMailInformation: 'Bekijk uw mail. We hebben u een mail gestuurd om uw wachtwoord te resetten',
    editPassword: 'Wachtwoord wijzigen',
    newPassword: 'Nieuw wachtwoord',
    repeatPassword: 'Herhaal wachtwoord',
    save: 'Opslaan',
    general: 'Algemeen',
    id: 'ID',
    number: 'Nummer',
    status: 'Status',
    title: 'Titel',
    characteristic: 'Kenmerk',
    questions: 'Vragen',
    question: 'Vraag',
    generalQuestion: 'Algemene vraag',
    generalDescription: 'Algemene beschrijving',
    questionType: 'Type',
    createdOn: 'Aangemaakt op',
    lastUpdated: 'Laatste update',
    results: 'resultaten',
    headerText: 'Koptekst',
    footerText: 'Voettekst',
    addQuestion: 'Vraag toevoegen',
    editQuestion: 'Wijzig vraag',
    addSurvey: 'Vragenlijst toevoegen',
    editSurvey: 'Vragenlijst wijzigen',
    addingInformation: 'Extra toelichting / informatie',
    typeAnswer: 'Type antwoord',
    answer: 'Antwoord',
    score: 'Score',
    followUp: 'Naar vraag',
    addAnswer: 'Antwoord toevoegen',
    followUpQuestion: 'Doorgaan naar vraag:',
    content: 'Content',
    makeYourChoice: 'Maak uw keuze',
    campaigns: 'Campagnes',
    campaign: 'Campagne',
    profiles: 'Profielen',
    extras: 'Extras',
    settings: 'Instellingen',
    zipCode: 'Postcode',
    birthDate: "Geb.Datum",
    country: "Land",
    gender: 'Geslacht',
    education: 'Onderwijs',
    language: 'Taal',
    languageEnglish: 'Taal EN',
    languageOriginal: 'Taal Orgineel',
    icon: 'Icon',
    abbreviation: 'Afkorting',
    lists: 'Lijsten',
    campaignIntroText: 'Introductietekst campagne',
    surveyCreatedMessage: 'Vragenlijst is aangemaakt!',
    surveyEditedMessage: 'Vragenlijst is bijgewerkt',
    surveyNotCreatedMessage: 'Vragenlijst is niet aangemaakt!',
    questionDeleteTooltip: 'Een antwoord wordt alleen verwijderd van nieuw ingevulde vragenlijsten. Eerder gekozen antwoorden blijven bestaan.',
    surveyPartlyEdited: 'Het antwoord is verwijderd van de vraag, maar bij een export van de eerdere data wordt het nog steeds weergegeven voor personen die het eerder hadden gekozen.',
    surveyNotEditedMessage: 'Vraag kan niet worden verwijderd als die al beantwoord is door iemand',
    campaignCreatedMessage: 'Campagne is aangemaakt!',
    campaignEditedMessage: 'Campagne is bijgewerkt',
    campaignNotCreatedMessage: 'Campagne is niet aangemaakt!',
    campaignNotEditedMessage: 'Campagne is niet bijgewerkt',
    campaignSentStatusLabel: 'Campagne status',
    campaignSentDateLabel: 'Campagne verstuur datum',
    selectSurvey: 'Selecteer een vragenlijst',
    isEqual: 'Is gelijk aan',
    isLess: 'Is minder',
    isGreater: 'Is groter',
    and: 'EN',
    valid: 'Geldig',
    from: "Van",
    to: 'Tot',
    numberOfMatchingProfiles: "Aantal matchende profielen",
    maxValue: "Maximale waarde",
    maximaleCharacters: 'Maximaal aantal karakters',
    minValue: "Minimale waarde",
    steps: "Stappen van",
    updateProfiles: 'Check profielen',
    displayType: 'Type weergave',
    addOwnOption: 'Eigen antwoord toevoegen',
    displayInDropdown: 'Weergeven in dropdown',
    canHaveMultipleAnswers: 'Kan meerdere antwoorden bevatten',
    startCampaign: 'Start de campagne',
    startSurvey: 'Start de vragenlijst',
    goToEndOfSurvey: 'Ga naar het einde van de vragenlijst',
    userInputRequired: 'This is an open question',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    role: 'Rol',
    name: 'Naam',
    languageStatus: 'Taal status',
    labelTranslations: 'Label vertalingen',
    endSurvey: 'Einde vragenlijst',
    endCampaign: 'Einde campagne',
    questionOfSurvey: 'Vraag 8 ....... 10',
    nextQuestion: 'Volgende vraag',
    previousQuestion: 'Vorige vraag',
    editLanguage: 'Wijzig taal',
    usersAndRoles: 'Gebruikers & rollen',
    languages: 'Talen',
    users: 'Gebruikers',
    roles: 'Rollen',
    addRoles: 'Rollen toevoegen',
    addUser: 'Gebruiker toevoegen',
    emailAlreadyExists: 'E mail bestaat al',
    postalCodeFormat: 'Postcode format (NNNN-AA = 8152-JJ)',
    sendAt: 'Versturen op',
    sendNow: 'Nu versturen',
    sendStatusNotSend: 'Niet verstuurd',
    sendStatusPartialSend: 'Gedeeltelijk verstuurd',
    sendStatusSent: "Verstuurd", 
    images: "Afbeeldingen (max 5)",
    uploadImage: "Afbeelding uploaden",
    goesToQuestion: 'Gaat naar vraag',
    goToCampaignLinkTitle: 'Link tekst voor de vragenlijst',
    unsubscribeEmailLinkTitle: 'Uitschrijven tekst label',
    unsubscribeProfileText: 'Uitschrijven profiel tekst',
    unsubscribeProfileTitle: 'Uitschrijven profiel titel',
    maritalStatus: 'Burgelijke staat',
    income: 'Inkomen',
    smoking: 'Rookt',
    countryOfBirth: 'Geboorteland',
    diabetesDuration: 'Diabetesduur',
    age: 'Leeftijd',
    year: 'jaar',
    languageHasBeenUpdated: 'Taal is bijgewerkt',
    languageHasNotBeenUpdated: 'Taal is niet bijgewerkt ',
    userHasBeenUpdated: 'Gebruiker is bijgewerkt',
    userHasNotBeenUpdated: 'Gebruiker is niet bijgewerkt',
    userIsCreated: 'Gebruiker is aangemaakt',
    userIsNotCreated: 'Gebruiker is niet aangemaakt',
    roleIsCreated: 'Rol is aangemaakt',
    roleIsNotCreated: 'Rol is niet aangemaakt',
    roleHasBeenUpdated: 'Rol is bijgewerkt',
    roleHasNotBeenUpdated: 'Rol is niet bijgewerkt',
    userIsDeleted: 'Gebruiker is verwijderd',
    userIsNotDeleted: 'Gebruiker is niet verwijderd',
    roleIsDeleted: 'Rol is verwijderd',
    roleIsNotDeleted: 'Rol is niet verwijderd',
    user: 'Gebruiker',
    questionnaireView : 'Vragenlijst bekijken',
    questionnaireEdit : 'Vragenlijst bewerken',
    questionnaireDelete : 'Vragenlijst verwijderen',
    campaignView : 'Campagne bekijken',
    campaignEdit : 'Campagne bewerken',
    campaignDelete : 'Campagne verwijderen',
    settingsView : 'Instellingen bekijken',
    settingsEdit : 'Instellingen bewerken',
    settingsDelete : 'Instellingen verwijderen',
    profileView : 'Profiel bekijken',
    noAccess: 'U heeft geen permissie om deze pagina te kunnen bekijken!',
    close: 'Sluiten',
    placeholder: 'Eigen antwoord placeholder',
    copy: 'Kopiëren',
    changeOrder: 'Volgorde aanpassen',
    genderEnum: {
        0: 'Onbekend',
        1: 'Man',
        2: 'Vrouw',
    },
    educationEnum: {
        0: 'Onbekend',
        1: 'Basis onderwijs',
        2: 'Praktijk onderwijs',
        3: 'Vmbo',
        4: 'Havo',
        5: 'Vwo',
        6: 'Mbo',
        7: 'Hbo',
        8: 'Universiteit',
        9: 'LBO, praktijkonderwijs, VSO',
        10: 'Vmbo',
        11: 'Mavo',
        12: 'Havo/Vwo',
    },
    maritalStatusEnum: {
        0: 'Onbekend',
        1: 'Gehuwd/ samenwonend/ relatie/ LAT-relatie',
        2: 'Alleenstaand',
    },
    smokingEnum: {
        0: 'Onbekend',
        1: 'Ja',
        2: 'Nee',
        3: 'Soms',
    },
    statusTypeEnum: {
        0: 'Onbekend',
        1: 'Gereed',
        2: 'Concept',
        3: 'Archief'
    },
    questionTypeEnum: {
        0: 'Onbekend',
        1: 'Open',
        2: 'Meerkeuze',
        3: 'Slider'
    },
    incomeEnum: {
        0: 'Onbekend',
        1: 'Minimaal',
        2: 'Onder modaal',
        3: 'Modaal',
        4: 'Tussen 1 en 2 keer modaal ',
        5: 'Twee keer modaal of meer',
    },
    profileOperatorEnum: {
        0: 'Gelijk aan',
        1: 'Groter dan',
        2: 'Minder dan'
    },
    columnNamesEnum: {
        0: 'Postcode',
        1: 'Geboortedatum',
        2: 'Land',
        // 3: 'Taal',
        4: 'Geslacht',
        5: 'Opleiding',
        6: 'Geboorteland',
        7: 'Rookt',
        8: 'Inkomen',
        9: 'Burgelijke staat',
        10: 'Diabetes duur',
        11: 'Campagne deelname'
    },
    displayTypeEnum: {
        0: 'Unknown',
        1: 'Tekst',
        2: 'Datum',
        3: 'Nummer',
        4: 'Postcode',
        5: 'Email',
        6: 'Land'
    },
    activeTypeEnum: {
        0: 'Inactief',
        1: 'Actief'
    },
    MultipleChoiceValidationText: 'Validatietekst Multiplechoice',
    TextValidationText: 'Validatietekst leeg tekstveld',
    NoNumberValidationText: 'Validatietekst geen nummer',
    NumberTooHighValidationText: 'Validatietekst te hoog nummer',
    NumberTooLowValidationText: 'Validatietekst te laag nummer',
    InvalidEmailValidationText: 'Validatietekst invalide email',
    NoEmailValidationText: 'Validatietekst geen email',
    InvalidPostalCodeValidationText: 'Validatietekst postcode',
    NoPostalCodeValidationText: 'Validatietekst geen postcode',
    invalidDateValidationText: 'Validatietekst ongeldige datum',
    stringTooLongValidation: 'Validatietekst tekst te lang',
    EmailAddress: 'Email adres',
    EmailAddressConfirm: 'Bevestig email address',
    EmailAddressNotEqualValidation: 'Email is niet gelijk',
    ErrorInPostQuestionAnswer: 'Er is een fout opgetreden bij het versturen van uw antwoord, probeer het alstublieft opnieuw',
    ExportLoading: 'Export wordt geladen. Dit kan enkele momenten duren.',
    Export: 'Exporteren',
    ProfileCampaign: 'Is Profiel Campagne',
    Yes: 'Ja',
    No: 'Nee',
    ProfileLoadingMessage: 'Uw eerder ingevulde antwoorden worden opgehaald. Een moment geduld alstublieft..',
    Copy: 'Kopiëren',
    ConfirmMultipleAnswer: 'Het kiezen van meerdere antwoorden haalt de mogelijkheid weg om per antwoord een vervolgvraag mee te geven, weet u zeker dat u dit wil?',
    Confirm: 'Doorgaan',
    Cancel: 'Annuleren',
}
