import { IdentityProfile, Policies, Question, QuestionType, StatusType, Survey, SurveyText } from '../types/model';
import { copySurvey, getSurvey, upsertSurvey } from '../actions/CMS/surveyActions'

import NoAccessComponent from '../components/NoAccessComponent';
import React from "react";
import SurveyForm from "../components/forms/SurveyForm";
import { connect } from "react-redux";
import { createGuid } from "../helpers/guidHelper";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';
import PageLoader from '../components/PageLoader';

interface State {
    survey: Survey | null,
    isLoading: boolean,
}

interface StateProps {
    selectedLanguageId: number,
    i18n: any
    profile: IdentityProfile
}

class SurveyContainer extends React.Component<any, State> {
    state = {
        survey: null,
        isLoading: true,
    }

    async componentDidMount() {
        this.setState({isLoading: true})
        var survey = await getSurvey(this.props.match.params.id);
        this.setState({isLoading: false})
        if (survey) {
            survey.questions = survey.questions?.sort((a, b) => a.rank > b.rank ? 1 : -1);
        }
        this.setState({ survey })
    }

    removeQuestion = async (values: any, row: any) => {
        const { i18n } = this.props;
        if (this.state.survey) {
            var survey = this.state.survey as unknown as Survey;

            survey.questions?.forEach((q) => {
                if (q) {
                    q.answers?.forEach((a) => {
                        if (a.followUpQuestionGuid === row.original.guid) {
                            a.followUpQuestionGuid = null;
                        }
                    })
                }
            })

            var question = survey.questions?.find((e) => row.original.guid === e.guid);
            if (question) {
                if (question.id === 0) {
                    survey.questions = survey?.questions?.filter((e: any) => e.guid !== question?.guid);
                } else {
                    question.id = -Math.abs(question.id as unknown as number)
                }
            }

            var calcRank = 100;
            for (let index = 0; index < survey.questions!.length; index++) {
                const element = survey.questions![index];
                if (element.id! < 0)
                    continue;
                element.rank = calcRank;

                for (let index = 0; index < element.answers!.length; index++) {
                    const answerElement = element.answers![index];
                    var prefix = calcRank.toString().substring(0, calcRank.toString().length - 2)
                    var postfix = answerElement.rank.toString().substring(answerElement.rank.toString().length - 2, answerElement.rank.toString().length)
                    answerElement.rank = parseInt(prefix + postfix);
                }

                calcRank += 100;
            }

            await upsertSurvey(survey).then(async () => {
                toast.success(survey.id === 0 ? i18n.surveyCreatedMessage : i18n.surveyEditedMessage);

                var updatedSurvey = await getSurvey(this.props.match.params.id);
                if (updatedSurvey) {
                    updatedSurvey.questions = updatedSurvey.questions?.sort((a, b) => a.rank > b.rank ? 1 : -1);
                }
                this.setState({ survey: updatedSurvey })

            }).catch(((e) => {
                toast.error(survey.id === 0 ? i18n.surveyNotCreatedMessage : i18n.surveyNotEditedMessage);
            }))
        }
    }

    reOrderSurvey = async (row: any, position: number) => {
        const { i18n } = this.props;
        if (this.state.survey) {
            var survey = this.state.survey as unknown as Survey;


            var element = survey.questions![row.index];
            survey.questions!.splice(row.index, 1);
            survey.questions!.splice(Math.max(position, 0), 0, element);


            var calcRank = 100;
            for (let index = 0; index < survey.questions!.length; index++) {
                const element = survey.questions![index];
                if (element.id! < 0)
                    continue;
                element.rank = calcRank;

                for (let index = 0; index < element.answers!.length; index++) {
                    const answerElement = element.answers![index];
                    var prefix = calcRank.toString().substring(0, calcRank.toString().length - 2)
                    var postfix = answerElement.rank.toString().substring(answerElement.rank.toString().length - 2, answerElement.rank.toString().length)
                    answerElement.rank = parseInt(prefix + postfix);
                }

                calcRank += 100;
            }

            await upsertSurvey(survey).then(async () => {
                toast.success(survey.id === 0 ? i18n.surveyCreatedMessage : i18n.surveyEditedMessage);

                var updatedSurvey = await getSurvey(this.props.match.params.id);
                if (updatedSurvey) {
                    updatedSurvey.questions = updatedSurvey.questions?.sort((a, b) => a.rank > b.rank ? 1 : -1);
                }
                this.setState({ survey: updatedSurvey })

            }).catch(((e) => {
                toast.error(survey.id === 0 ? i18n.surveyNotCreatedMessage : i18n.surveyNotEditedMessage);
            }))
        }
    }

    copySurvey = async (id: string) => {
        await copySurvey(id).then(async () => {});
    }

    upsertSurvey = async (values: any, saveQuestion: boolean) => {
        const { i18n } = this.props;

        var survey = (this.state.survey as unknown as Survey);
        survey = survey ? survey : {} as Survey
        survey.surveyTexts = survey.surveyTexts ? survey.surveyTexts : [];

        if (values) {
            var stub = 'new_' + Math.floor(Math.random() * 10000);
            survey.guid = this.props.match.params.id;
            survey.characteristic = values.characteristic == '' ? stub : values.characteristic;
            survey.status = values.status;
            survey.isArchived = values.status === StatusType.isArchived;
            survey.title = values.title == '' ? stub : values.title;
            survey.code = values.code == '' ? stub : values.code;

            var surveyText = survey?.surveyTexts.find((st: SurveyText) => st.languageId == this.props.selectedLanguageId);

            if (surveyText) {
                surveyText.headerText = values.headerText;
                surveyText.footerText = values.footerText;
                surveyText.title = values.title;
            }
            else {
                survey.surveyTexts.push({
                    id: 0,
                    title: values.title == '' ? stub : values.title,
                    footerText: values.footerText,
                    headerText: values.headerText,
                    languageId: this.props.selectedLanguageId
                })
            }
        }
        await upsertSurvey(survey).then(async () => {
            toast.success(survey.id === 0 ? i18n.surveyCreatedMessage : i18n.surveyEditedMessage);
            if (!saveQuestion) {
                this.props.history.push({ pathname: `/CMS/Vragenlijsten` });
            }
            else {
                var updatedSurvey = await getSurvey(this.props.match.params.id);
                if (updatedSurvey) {
                    updatedSurvey.questions = updatedSurvey.questions?.sort((a, b) => a.rank > b.rank ? 1 : -1);
                }
                this.setState({ survey: updatedSurvey })
            }
        }).catch((e) => {
            if(e.message?.includes("409"))
                toast.warn(i18n.surveyPartlyEdited);
            
            else
                toast.error(survey.id === 0 ? i18n.surveyNotCreatedMessage : i18n.surveyNotEditedMessage);
        })
    }

    upsertQuestion = (question: Question, formQuestionValue: any): Question => {
        var survey = this.state.survey as unknown as Survey;
        var rank = "100"
        if (survey && survey.questions) {
            rank = formQuestionValue?.rank ? formQuestionValue.rank : (survey.questions.length + 1) + "00";
        }

        question.guid = question.guid ? question.guid : createGuid();
        question.rank = parseInt(rank);
        question.id = question.id ? formQuestionValue.id : 0
        question.description = formQuestionValue.generalDescription
        question.questionType = parseInt(formQuestionValue.type)
        question.createdOn = new Date();
        question.status = StatusType.ready;

        return question
    }

    upsertMultipleChoiceAnswers = (question: Question, values: any) => {
        var formAnswersValues = values.answers;
        if (values.question.addOwnAnswer) {
            var surveyNotNull = this.state.survey as unknown as Survey;

            var ownValueRank;
            if (values.question.id === 0) {
                ownValueRank = ((surveyNotNull?.questions ? surveyNotNull.questions?.length : 0) + 1)
            } else {
                var questionRank = values.question.rank;
                ownValueRank = questionRank.toString().length > 3 ? questionRank.toString().substring(0, 2) : questionRank.toString().substring(0, 1)
            }

            var currentRank = formAnswersValues ? formAnswersValues.length : 1;

            if (currentRank.toString().length > 1) {
                ownValueRank = parseInt(ownValueRank.toString() + (currentRank + 1).toString())
            } else {
                ownValueRank = parseInt(ownValueRank.toString() + "0" + (currentRank + 1).toString())
            }

            values.ownAnswer.rank = ownValueRank;

            formAnswersValues.push(
                values.ownAnswer
            )
        }

        for (var i = 0; i < formAnswersValues.length; i++) {
            var answer = question?.answers?.find((a) => a.guid === formAnswersValues[i].guid);
            if (answer) {
                answer.description = formAnswersValues[i].generalDescription;

                var answerRank = formAnswersValues[i].rank;
                if (answerRank < 100) {
                    var prefix = question.rank.toString().substring(0, question.rank.toString().length - 2)

                    if (answerRank < 10)
                        answerRank = parseInt(prefix + "0" + answerRank)
                    else
                        answerRank = parseInt(prefix + answerRank)
                }

                answer.rank = answerRank;
                answer.score = formAnswersValues[i].score;
                answer.followUpQuestionGuid = formAnswersValues[i].followUpQuestionGuid
                answer.isOwnAnswer = formAnswersValues[i].isOwnAnswer

                var answerText = answer?.answerTextViewModels.find(e => e.languageId === values.languageId);
                if (answerText) {
                    answerText.answerText = formAnswersValues[i].answerText;
                } else {
                    answer?.answerTextViewModels.push({
                        answerText: formAnswersValues[i].answerText,
                        languageId: values.languageId,
                    })
                }
                if (answer.isOwnAnswer) {
                    question.placeholder = formAnswersValues[i].answerText
                }
            } else {
                var answerRank = formAnswersValues[i].rank;
                if (answerRank < 100) {
                    var prefix = question.rank.toString().substring(0, question.rank.toString().length - 2)

                    if (answerRank < 10)
                        answerRank = parseInt(prefix + "0" + answerRank)
                    else
                        answerRank = parseInt(prefix + answerRank)
                }

                if (formAnswersValues[i].isOwnAnswer) {
                    question.placeholder = formAnswersValues[i].answerText
                }

                question.answers = question.answers ? question.answers : []
                question?.answers?.push({
                    isOwnAnswer: formAnswersValues[i].isOwnAnswer,
                    id: 0,
                    guid: createGuid(),
                    // isOwnAnswer: formAnswersValues[i].isOwnAnswer,
                    score: formAnswersValues[i].score,
                    rank: answerRank,
                    followUpQuestionGuid: formAnswersValues[i].followUpQuestionGuid,
                    description: formAnswersValues[i].generalDescription,
                    answerTextViewModels: [{
                        answerText: formAnswersValues[i].answerText,
                        languageId: values.languageId,
                    }]
                })
            }
        }

        // set deleted ones to a negative number
        question.answers?.map(a => {
            if (a.id) {
                var answerVal = formAnswersValues.find((fav: any) => fav.id === a.id);
                if (!answerVal)
                    a.id = -Math.abs(a.id)
            }
            a.id = a.isOwnAnswer ? 3 : a.id;
            return a;
        })



        return question;
    }

    upsertQuestionAndAnsers = (values: any) => {
        var formQuestionValue = values.question;
        var survey = this.state.survey as unknown as Survey;

        if (!survey) {
            survey = {
                id: 0,
            } as Survey
        }

        survey.questions = survey?.questions ? survey.questions : []
        var question = survey.questions.find((q) => q.guid === formQuestionValue.guid);

        if (question && question.guid) {
            var updatedQuestion = this.upsertQuestion(question, formQuestionValue);
            if (formQuestionValue.type === QuestionType.multipleChoice) {
                updatedQuestion = this.upsertMultipleChoiceAnswers(updatedQuestion, values);
                updatedQuestion.showInDropdown = formQuestionValue.showInDropdown;
                updatedQuestion.addOwnAnswer = formQuestionValue.addOwnAnswer
                updatedQuestion.hasMultipleAnswers = formQuestionValue.hasMultipleAnswers
            }
            if (formQuestionValue.type === QuestionType.open) {
                updatedQuestion.displayType = formQuestionValue.displayType;
                updatedQuestion.minValue = formQuestionValue.minValue
                updatedQuestion.maxValue = formQuestionValue.maxValue
                updatedQuestion.answers = [{ id: 3, rank: question.rank, description: '', answerTextViewModels: [], followUpQuestionGuid: formQuestionValue.followUpQuestionGuid }]
            }
            if (formQuestionValue.type === QuestionType.slider) {
                updatedQuestion.minValue = formQuestionValue.minValue;
                updatedQuestion.maxValue = formQuestionValue.maxValue;
                updatedQuestion.steps = formQuestionValue.steps;
                updatedQuestion.answers = [{ id: 3, rank: question.rank, description: '', answerTextViewModels: [], followUpQuestionGuid: formQuestionValue.followUpQuestionGuid }]
            }

            var existingQuestionText = updatedQuestion?.questionTexts?.find(qt => qt.languageId === formQuestionValue.languageId)
            if (existingQuestionText) {
                existingQuestionText.addingInformation = formQuestionValue.addingInformation;
                existingQuestionText.description = formQuestionValue.description;
                existingQuestionText.regexValidationFormat = formQuestionValue.regexValidationFormat;
                existingQuestionText.createdOn = new Date();
            } else {
                updatedQuestion.questionTexts = question?.questionTexts ? question.questionTexts : []
                updatedQuestion.questionTexts.push({
                    addingInformation: formQuestionValue.addingInformation,
                    description: formQuestionValue.description,
                    regexValidationFormat: formQuestionValue.regexValidationFormat,
                    languageId: formQuestionValue.languageId
                })
            }

        } else {
            var newQuestion = {} as Question;
            newQuestion = this.upsertQuestion(newQuestion, formQuestionValue);

            if (formQuestionValue.type === QuestionType.multipleChoice) {
                newQuestion = this.upsertMultipleChoiceAnswers(newQuestion, values)
                newQuestion.showInDropdown = formQuestionValue.showInDropdown;
                newQuestion.addOwnAnswer = formQuestionValue.addOwnAnswer;
                newQuestion.hasMultipleAnswers = formQuestionValue.hasMultipleAnswers
            }
            if (formQuestionValue.type === QuestionType.open) {
                var rank = "100"
                if (survey && survey.questions) {
                    rank = (survey.questions.length + 1) + "00";
                }
                newQuestion.displayType = formQuestionValue.displayType
                newQuestion.minValue = formQuestionValue.minValue
                newQuestion.maxValue = formQuestionValue.maxValue
                newQuestion.answers = [{ id: 3, rank: parseInt(rank), description: '', answerTextViewModels: [], followUpQuestionGuid: formQuestionValue.followUpQuestionGuid }]
            }
            if (formQuestionValue.type === QuestionType.slider) {
                var rank = "100"
                if (survey && survey.questions) {
                    rank = (survey.questions.length + 1) + "00";
                }
                newQuestion.minValue = formQuestionValue.minValue;
                newQuestion.maxValue = formQuestionValue.maxValue;
                newQuestion.steps = formQuestionValue.steps;

                newQuestion.answers = [{ id: 3, rank: parseInt(rank), description: '', answerTextViewModels: [], followUpQuestionGuid: formQuestionValue.followUpQuestionGuid }]
            }
            newQuestion.questionTexts = [{
                addingInformation: formQuestionValue.addingInformation,
                description: formQuestionValue.description,
                languageId: formQuestionValue.languageId,
                regexValidationFormat: formQuestionValue.regexValidationFormat
            }]
            survey.questions.push(newQuestion)
        }
        let newQuestions = survey.questions.map(s => ({ ...s }));
        survey.questions = newQuestions;

        this.setState({ survey })
    }

    copyQuestionAndAnswers = (values: any) => {
        values.question.id = 0;
        values.question.guid = undefined;
        values.question.rank = undefined;

        for (var i = 0; i < values.answers.length; i++) {
            values.answers[i].guid = undefined;
            values.answers[i].rank = 0;
        }

        this.upsertQuestionAndAnsers(values);
    }


    render() {
        var { survey } = this.state;
        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];

        var isValid = claims.some((c: any) => [Policies.QuestionnaireEdit, Policies.QuestionnaireView, Policies.QuestionnaireDelete].includes(c))
        return (
            this.state.isLoading ? <PageLoader/> : 
            isValid ?
                <SurveyForm
                    survey={survey}
                    onSubmitSurvey={this.upsertSurvey}
                    onSubmitQuestion={this.upsertQuestionAndAnsers}
                    removeQuestion={this.removeQuestion}
                    copyQuestion={this.copyQuestionAndAnswers}
                    moveQuestion={this.reOrderSurvey}
                    copySurvey={this.copySurvey}
                /> : <NoAccessComponent />
        )
    }
}

const mapStateToProps = (state: any): StateProps => {
    return ({
        selectedLanguageId: state.selectedLanguageId,
        i18n: state.i18n,
        profile: state.profile
    })
}

export default withRouter(connect(mapStateToProps, {})(SurveyContainer))

