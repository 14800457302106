


import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm, change, registerField, unregisterField } from 'redux-form';
import { DisplayType, IdentityProfile, Policies, Question, QuestionType } from '../../types/model';
import ComponentHeader from '../ComponentHeader';
import required from '../../validators/required';
import draftToHtml from 'draftjs-to-html';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Gridview from '../gridview/GridviewComponent';
import { answersColumns } from '../../constants/tableColumns';
import { Modal } from '../Modal';
import { ConfirmModal } from '../ConfirmModal';
import { DropdownComponent } from '../InputComponents/DropdownComponent';
import { TextInputComponent } from '../InputComponents/TextInputComponent';
import { Checkbox } from '../InputComponents/CheckboxInputComponent';
import { enumToDropdown } from '../../helpers/commonHelper';
import TextEditorComponent from '../InputComponents/TextEditorComponent';
import integer from '../../validators/integer';
import minValue from '../../validators/minValue';
import { ConvertHtmlToEditorState } from '../../helpers/textAreaHelper';
import { EditorState, convertToRaw } from "draft-js";
import maxValue from '../../validators/maxValue';
import { collapseTextChangeRangesAcrossMultipleVersions, isThisTypeNode } from 'typescript';


interface OwnProps {
    question: Question | undefined;
    questions: any;
    closeQuestionModal: () => void;
    onSubmitQuestion: (values: any) => void;
    copyQuestion: (values: any) => void;
}

interface MatchParams {
    id: string
}

interface DispatchProps {
    dispatch: any;
    updateFormValue: (field: string, value: any) => any;
}

interface State {
    editorStateExtraInformation: EditorState;
    question: Question | undefined;
    forceUpdate: boolean;
    showModal: boolean;
}

interface StateProps {
    formValues: any;
    selectedLanguageId: number;
    initialValues: any;
    profile: IdentityProfile
    i18n: any
}

const initialValues = (index: number, numberOfQuestions: number, questionRank?: number, isOwnAnswer?: boolean, i18n?: any): InitialValuesAnswer => {
    var questionStrippedRank = questionRank ? questionRank.toString().substring(0, questionRank.toString().length - 2) : (numberOfQuestions + 1).toString();
    var newRank = index.toString().length === 1 ? parseInt(questionStrippedRank + "0" + index) : parseInt(questionStrippedRank + index);

    return ({
        id: 0,
        guid: "",
        rank: newRank,
        generalDescription: isOwnAnswer ? i18n.userInputRequired : "",
        answerText: isOwnAnswer ? i18n.userInputRequired : "",
        score: 1,
        followUpQuestionGuid: null,
        isOwnAnswer: isOwnAnswer ? true : false
    });
};

interface InitialValuesAnswer {
    id: number,
    guid: string,
    rank: number,
    generalDescription: string,
    answerText: string,
    score: number,
    followUpQuestionGuid: string | null,
    isOwnAnswer?: boolean
}

const endOfSurveyGuid = '246f6869-b4af-4591-ba8e-aee503c51ff1';

type Props = OwnProps & DispatchProps & StateProps & RouteComponentProps<MatchParams>;

const formName = "QuestionForm"
class QuestionForm extends React.Component<Props & InjectedFormProps<{}, any>, State> {
    constructor(props: Props & InjectedFormProps<{}, any>) {
        super(props);
        this.state = {
            editorStateExtraInformation: EditorState.createEmpty(),
            question: undefined,
            forceUpdate: true,
            showModal: false,
        }
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.state.forceUpdate) {
            var questionText = this.props.question != null ? this.props.question?.questionTexts?.find((st) => st.languageId === this.props.selectedLanguageId) : null;
            var editorStateExtraInformation = ConvertHtmlToEditorState(questionText && questionText.addingInformation ? questionText.addingInformation : "<div></div>");

            this.setState({ editorStateExtraInformation: editorStateExtraInformation, forceUpdate: false })
        }
    }

    onEditorStateChange = (editor: EditorState, accessor?: string) => {
        const rawContentState = convertToRaw(editor.getCurrentContent());

        rawContentState.blocks.map(block => {
            const text = block.text;

            if(rawContentState.blocks.length === 1 && text === '') return block;

            if(text === '' && block){
                block.text = ' '
            }
            return block;
        })

        const html = draftToHtml(rawContentState);

        if (accessor != null) {
            this.props.updateFormValue(accessor, html);
            this.setState({ editorStateExtraInformation: editor })
        }
    }

    shouldComponentUpdate(prevProps: any, nextState: any) {
        if (nextState !== this.state) {
            return true;
        }
        if (prevProps.formValues && this.props.formValues) {
            if (prevProps.formValues.question?.addingInformation !== this.props.formValues.question?.addingInformation)
                return true;
            if (prevProps.formValues.QuestionForm !== this.props.formValues.QuestionForm)
                return true;
            if (prevProps.formValues.question.type !== this.props.formValues.question.type) {
                return true
            }
            if (prevProps.formValues.question.displayType !== this.props.formValues.question.displayType) {
                return true
            }
            if (prevProps.formValues.answers?.length == this.props.formValues.answers?.length) {
                if (this.state.forceUpdate)
                    return true;
                else {
                    return false;
                }
            }
        }
        return true;
    }

    manageFields = (isAddingRows: boolean, row?: any, isOwnValue?: boolean) => {
        var index = row?.index;

        var { formValues } = this.props;
        var initialVal = initialValues(this.props.formValues.answers.length + 1, this.props.questions.length, this.props.question?.rank, isOwnValue, this.props.i18n);
        if(this.props.formValues.question && this.props.formValues.question.hasMultipleAnswers === true){
            initialVal = {...initialVal, followUpQuestionGuid: this.props.formValues.question.followUpQuestionGuid}
        }

        Object.keys(initialVal).forEach(key => {
            if (isAddingRows) {
                this.props.dispatch(registerField(formName, `answers[${formValues.answers.length}].${key}`, "Field"));
                this.props.change(`answers[${formValues.answers.length}][${key}]`, (initialVal[key as keyof InitialValuesAnswer] as any));
            } else {
                var indexOfOwnValue = formValues.answers.findIndex((x: any) => x.isOwnAnswer);

                if (indexOfOwnValue > 0 && row === null) {
                    this.props.dispatch(unregisterField(formName, `answers[${indexOfOwnValue}].${key}`));
                } else {
                    this.props.dispatch(unregisterField(formName, `answers[${index}].${key}`));
                }
            }
        })

        var indexOfOwnValue = formValues.answers.findIndex((x: any) => x.isOwnAnswer);

        if (!isAddingRows && row?.original.isOwnAnswer) {
            this.props.updateFormValue('question.addOwnAnswer', false);
            formValues.answers.splice(indexOfOwnValue, 1);
        }

        else if (!isAddingRows) {
            if (isOwnValue && !isAddingRows && this.props.formValues?.question?.addOwnAnswer) {
                this.props.updateFormValue('question.addOwnAnswer', false)
                formValues.answers.splice(indexOfOwnValue, 1);
            }
            else {
                formValues.answers.splice(index, 1);
            }
        }

        for (let index = 0; index < formValues.answers.length; index++) {
            const element = formValues.answers[index];

            var answerRank = element.rank;
            if (answerRank < 100) {
                var prefix = element.rank.toString().substring(0, element.rank.toString().length - 2);
                if (answerRank < 10)
                    answerRank = parseInt(prefix + "0" + (index + 1))
                else
                    answerRank = parseInt(prefix + "" + (index + 1))
            } else {

                var prefix2 = element.rank.toString().substring(0, element.rank.toString().length - 2);
                answerRank = parseInt(prefix2 + ((index + 1) >= 10 ? "" : "0") + (index + 1))
            }
            formValues.answers[index].rank = answerRank;
        }

        this.props.change(formName, formValues);
    }

    addOwnOptionToRow = (value: any) => {
        const form = this.props.formValues;
        if (value) {
            this.props.updateFormValue('question.addOwnAnswer', true);
            if(form.question?.hasMultipleAnswers){
                this.props.updateFormValue('ownAnswer.followUpQuestionGuid', form.question.followUpQuestionGuid)
            }
        } else {
            this.props.updateFormValue('question.addOwnAnswer', false);
        }
        this.setState({ forceUpdate: true })
    }

    multipleAnswerConfirm = () =>{
        if(this.props.formValues.question?.hasMultipleAnswers === true || (this.props.formValues.answers[0].Guid === undefined && this.props.formValues.answers[0].generalDescription === undefined)){
            this.ToggleMultipleAnswer();
            return;
        }
        this.setState({showModal: true});
    }

    CancelMultipleAnswer = () => {
        this.props.updateFormValue('question.hasMultipleAnswers', false);
        this.setState({showModal: false, forceUpdate: true});
        
        return;
    }

    ToggleMultipleAnswer = () => {
        const form = this.props.formValues;
        const multipleAnswers = form.question.hasMultipleAnswers
        const answers = form.answers;
        if (multipleAnswers) {
            for (let index = 0; index < answers.length; index++) {
                this.props.updateFormValue(`answers[${index}].followUpQuestionGuid`, form.question.followUpQuestionGuid);
            }
            if(form.question.addOwnAnswer){
                this.props.updateFormValue(`ownAnswer.followUpQuestionGuid`, form.question.followUpQuestionGuid);
            }
        }
        else{
            this.props.updateFormValue('question.followUpQuestionGuid', null);
        }
        this.setState({ forceUpdate: true, showModal: false })
    }

    MultipleAnswerFollowUpQuestion = (value: any) => {
        const form = this.props.formValues;
        form.answers.forEach((answer: { followUpQuestionGuid: any; }) => {
            answer.followUpQuestionGuid = value;
        });
        
        if(form.question.addOwnAnswer){
            form.ownAnswer.followUpQuestionGuid = value;
        }
    }

    copyQuestion = () => {
        this.props.copyQuestion(this.props.formValues);
    }

    questionContent = () => {
        var { formValues, i18n } = this.props;

        const claims = this.props.profile ? this.props.profile.roles[0].claims : [];
        const isEditable = claims.includes(Policies.QuestionnaireEdit);
        const isDeletable = claims.includes(Policies.QuestionnaireDelete);

        var questionTypes = enumToDropdown(i18n.questionTypeEnum);
        var displayTypes = enumToDropdown(i18n.displayTypeEnum);
        var headerText = this.state.question ? i18n.editQuestion : i18n.addQuestion
        var strippedRank = this.props.question?.rank?.toString().substring(0, this.props.question?.rank.toString().length - 2)

        // add records if there is no one yet
        if (this.props.formValues?.answers?.length === 0 && this.props.formValues?.question?.type === QuestionType.multipleChoice) {
            this.manageFields(true);
        }

        var questionDropdown = [];

        var question = this.props.formValues?.question;
        var questions = this.props.questions.filter((e: any) => e.id >= 0).sort((a: any, b: any) => a.rank > b.rank ? 1 : -1)
        var canAddItems = false;
        for (var i = 0; i < questions.length; i++) {
            if (canAddItems) {
                questionDropdown.push({ label: i18n.followUp + ' ' + (i + 1), value: this.props.questions[i].guid })
            }
            if (this.props.questions[i].guid === question?.guid)
                canAddItems = true;
        }

        questionDropdown.push({
            label: i18n.goToEndOfSurvey, value: endOfSurveyGuid
        });

        var followUpQuestionField = <Field
            component={DropdownComponent}
            name={`question.followUpQuestionGuid`}
            readOnly={!isEditable}
            label={i18n.followUpQuestion}
            labelInComponent={true}
            options={questionDropdown}
        />;

        var deleteToolTip = i18n.questionDeleteTooltip;
        var answerColumns = answersColumns(questionDropdown, i18n, !isEditable, question?.hasMultipleAnswers ?? false)

        return (
            <>
                 { this.state.showModal &&  
                    <ConfirmModal content = {
                    <div >
                        <div className='modal-content'>
                            <div>
                                <p>{i18n.ConfirmMultipleAnswer}</p>
                            </div>

                            <div className='modal-actions' >
                                <button type="button" onClick={() => this.ToggleMultipleAnswer()} className="whiteButton" >{i18n.Confirm}</button>
                                <button type="button" onClick={() => this.CancelMultipleAnswer()} className="whiteButton" >{i18n.Cancel}</button>
                            </div>
                        </div>
                    </div>
                }
                />
            }

                <ComponentHeader onClose={this.props.closeQuestionModal} title={headerText} isForm={true} textButton={i18n.save} onCopy={this.props.question?.id && this.props.question.id > 0 ? this.copyQuestion : undefined} />
                <div className="columns">
                    <div className="column is-8">
                        <Field
                            component={TextInputComponent}
                            labelInComponent={true}
                            name="question.generalDescription"
                            type="text"
                            required={true}
                            disabled={!isEditable}
                            validate={required}
                            label={`${i18n.generalQuestion}:`}
                        />
                    </div>
                    <div className="column is-4">
                        <Field
                            component={DropdownComponent}
                            label={`${i18n.typeAnswer}:`}
                            name="question.type"
                            disabled={!isEditable}
                            readOnly={this.props.formValues?.question?.id !== 0 || !isEditable}
                            required={true}
                            validate={required}
                            labelInComponent={true}
                            options={questionTypes}
                        />
                    </div>
                </div>
                <div className="columns">
                    {this.props.formValues?.question?.type === QuestionType.slider ?
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                name="question.description"
                                type="text"
                                validate={required}
                                required={true}
                                readOnly={!isEditable}
                                disabled={!isEditable}
                                label={`${i18n.question}:`}
                            />
                        </div> :
                        <>
                            <div className="column is-8">
                                <Field
                                    component={TextInputComponent}
                                    labelInComponent={true}
                                    name="question.description"
                                    type="text"
                                    readOnly={!isEditable}
                                    disabled={!isEditable}
                                    validate={required}
                                    required={true}
                                    label={`${i18n.question}:`}
                                />
                            </div>
                            <div className="column is-4">
                                <div className="columns">
                                    <div className="column">
                                        {this.props.formValues?.question?.type === QuestionType.multipleChoice ?
                                            <>
                                                <Field
                                                    component={Checkbox}
                                                    label={i18n.addOwnOption}
                                                    name="question.addOwnAnswer"
                                                    disabled={!isEditable}
                                                    readOnly={!isEditable}
                                                    onClick={this.addOwnOptionToRow}
                                                    // onClickCheckbox={}
                                                    displayInline={true}
                                                />
                                                <Field
                                                    component={Checkbox}
                                                    label={i18n.displayInDropdown}
                                                    disabled={!isEditable}
                                                    readOnly={!isEditable}
                                                    name="question.showInDropdown"
                                                    displayInline={true}
                                                />
                                                <Field
                                                    component={Checkbox}
                                                    label={i18n.canHaveMultipleAnswers}
                                                    disabled={!isEditable}
                                                    readOnly={!isEditable}
                                                    checked={formValues.question.hasMultipleAnswers}
                                                    name="question.hasMultipleAnswers"
                                                    onClick={this.multipleAnswerConfirm}
                                                    displayInline={true}
                                                />
                                            </>
                                            :
                                            <Field
                                                component={DropdownComponent}
                                                label={`${i18n.displayType}:`}
                                                name="question.displayType"
                                                disabled={!isEditable}
                                                readOnly={this.props.formValues?.question?.id !== 0 || !isEditable}
                                                required={true}
                                                validate={required}
                                                labelInComponent={true}
                                                options={displayTypes}
                                            />
                                        }
                                    </div>
                                </div>

                            </div>
                        </>}
                </div>
                
                { formValues?.question?.hasMultipleAnswers &&
                    <div className='columns'>
                        <div className="column is-8">
                            <span style={{ color: '#009CDE', fontWeight: 700, fontSize: '16px' }}>{i18n.followUp}</span>
                            <Field
                                component={DropdownComponent}
                                readOnly={!isEditable}
                                name={`question.followUpQuestionGuid`}
                                options={questionDropdown}
                                onChange={this.MultipleAnswerFollowUpQuestion}
                            />
                        </div>
                    </div>
                }
                {this.props.formValues?.question?.type === QuestionType.slider &&
                    <div className="columns">
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                disabled={!isEditable}
                                name="question.minValue"
                                type="number"
                                readOnly={!isEditable}
                                validate={[integer, required, minValue]}
                                required={true}
                                label={`${i18n.minValue}:`}
                            />                        </div>
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                name="question.maxValue"
                                type="number"
                                readOnly={!isEditable}
                                disabled={!isEditable}
                                validate={[integer, required, maxValue]}
                                required={true}
                                label={`${i18n.maxValue}:`}
                            />                        </div>
                        <div className="column">
                            <Field
                                component={TextInputComponent}
                                labelInComponent={true}
                                disabled={!isEditable}
                                readOnly={!isEditable}
                                name="question.steps"
                                type="number"
                                validate={[integer, required]}
                                required={true}
                                label={`${i18n.steps}:`}
                            />
                        </div>
                    </div>
                }
                {formValues && formValues?.question.type !== QuestionType.multipleChoice &&
                    <div className="columns">
                        {formValues && (formValues?.question.displayType === DisplayType.number || formValues?.question.displayType === DisplayType.text || formValues?.question.displayType === DisplayType.postalCode) ?
                            <>
                                <div className="column">
                                    {followUpQuestionField}
                                </div>
                                {formValues?.question.displayType === DisplayType.number ?
                                    <>
                                        <div className="column">
                                            <Field
                                                component={TextInputComponent}
                                                labelInComponent={true}
                                                name="question.minValue"
                                                type="number"
                                                validate={[integer]}
                                                disabled={!isEditable}
                                                readOnly={!isEditable}
                                                required={true}
                                                label={`${i18n.minValue}:`}
                                            />
                                        </div>
                                        <div className="column">
                                            <Field
                                                component={TextInputComponent}
                                                labelInComponent={true}
                                                name="question.maxValue"
                                                disabled={!isEditable}
                                                readOnly={!isEditable}
                                                type="number"
                                                validate={[integer]}
                                                required={true}
                                                label={`${i18n.maxValue}:`}
                                            />
                                        </div>
                                    </>
                                    : formValues?.question.displayType === DisplayType.text ?
                                        <div className="column">
                                            <Field
                                                component={TextInputComponent}
                                                labelInComponent={true}
                                                name="question.maxValue"
                                                disabled={!isEditable}
                                                readOnly={!isEditable}
                                                type="number"
                                                validate={[integer]}
                                                required={true}
                                                label={`${i18n.maximaleCharacters}:`}
                                            />
                                        </div>
                                        :
                                        <div className="column">
                                            <Field
                                                component={TextInputComponent}
                                                labelInComponent={true}
                                                name="question.regexValidationFormat"
                                                disabled={!isEditable}
                                                readOnly={!isEditable}
                                                type="text"
                                                label={`${i18n.postalCodeFormat}:`}
                                            />
                                        </div>
                                }
                            </> :
                            <>
                                <div className="column">
                                    {followUpQuestionField}
                                </div>

                            </>
                        }
                    </div>
                }
                {
                    this.props.formValues?.question.addOwnAnswer &&
                    <>
                        <div className="columns">
                            <div className="column">
                                <span style={{ color: '#009CDE', fontWeight: 700, fontSize: '16px' }}>{i18n.placeholder}</span>
                                <Field
                                    component={TextInputComponent}
                                    type="text"
                                    required={true}
                                    readOnly={!isEditable}
                                    validate={required}
                                    name={`ownAnswer.answerText`}
                                />
                            </div>
                            <div className="column">
                                <span style={{ color: '#009CDE', fontWeight: 700, fontSize: '16px' }}>{i18n.score}</span>
                                <Field
                                    component={TextInputComponent}
                                    type="number"
                                    required={true}
                                    readOnly={!isEditable}
                                    validate={[required, integer]}
                                    name={`ownAnswer.score`}

                                />
                            </div>
                            { !this.props.formValues?.question.hasMultipleAnswers &&
                            <div className="column">
                                <span style={{ color: '#009CDE', fontWeight: 700, fontSize: '16px' }}>{i18n.followUp}</span>
                                <Field
                                    component={DropdownComponent}
                                    readOnly={!isEditable}
                                    name={`ownAnswer.followUpQuestionGuid`}
                                    options={questionDropdown}
                                />
                            </div>
                            }
                        </div>
                    </>
                }
                <div className="columns">
                    <div className="column">
                        <Field
                            component={TextEditorComponent}
                            label={i18n.addingInformation}
                            name="question.addingInformation"
                            disabled={!isEditable}
                            readOnly={!isEditable}
                            editorState={this.state.editorStateExtraInformation}
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        {formValues && formValues?.question.type === QuestionType.multipleChoice && formValues.answers.length > 0 &&
                            <Gridview
                                columns={answerColumns}
                                data={this.props.formValues ? this.props.formValues?.answers?.filter((e: any) => e.id >= 0).sort((a: any, b: any) => a.rank > b.rank ? 1 : -1)
                                    : []}
                                canDragAndDrop={this.props.formValues?.question?.type === QuestionType.multipleChoice}
                                deleteItem={this.props.formValues?.question?.type === QuestionType.multipleChoice && isDeletable}
                                onDeleteClick={this.manageFields}
                                deleteToolTip={deleteToolTip}
                            />
                        }
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        {this.props.formValues?.question?.type === QuestionType.multipleChoice && isEditable &&
                            <button type="button" onClick={() => this.manageFields(true)} className="whiteButton" >{i18n.addAnswer}</button>
                        }
                    </div>
                </div>
            </>
        )
    }

    submitQuestion = (e: any) => {
        const { onSubmitQuestion, closeQuestionModal } = this.props;
        onSubmitQuestion(e);
        closeQuestionModal();
    }

    render() {
        const { handleSubmit, i18n } = this.props;
        var content = this.questionContent();

        return (
            <>
                <form onSubmit={handleSubmit((e: any) => { this.submitQuestion(e) })}>
                    <Modal
                        content={content}
                    />
                </form>
            </>
        )
    }
}

const mapStateToProps = (state: any, props: OwnProps & RouteComponentProps<MatchParams>): StateProps => {
    var existingQuestionLanguage = props.question?.questionTexts?.find(e => (e.languageId === state.selectedLanguageId));

    var ownValue = null;
    var existingAnswersLanguage = props.question?.answers?.map(e => {
        var answer = e.answerTextViewModels.find(e => e.languageId === state.selectedLanguageId);

        if (e.isOwnAnswer) {
            ownValue = {
                id: e?.id,
                guid: e.guid,
                // rank: parseInt(e.rank.toString().charAt(0)),
                isOwnAnswer: e.isOwnAnswer,
                generalDescription: e.id === 3 ? state.i18n.userInputRequired : e.description,
                answerText: answer?.answerText,
                score: e.score,
                followUpQuestionGuid: e.followUpQuestionGuid
            }
            return null;
        }

        if (answer) {
            return ({
                id: e?.id,
                guid: e.guid,
                rank: e.rank,
                isOwnAnswer: e.isOwnAnswer,
                generalDescription: e.id === 3 ? state.i18n.userInputRequired : e.description,
                answerText: answer.answerText,
                score: e.score,
                followUpQuestionGuid: e.followUpQuestionGuid
            });
        } else {
            return ({
                id: e?.id,
                guid: e.guid,
                rank: e.rank,
                isOwnAnswer: e.isOwnAnswer,
                answerText: "",
                generalDescription: e.id === 3 ? state.i18n.userInputRequired : e.description,
                score: e.score,
                followUpQuestionGuid: e.followUpQuestionGuid
            })
        }
    });

    existingAnswersLanguage = existingAnswersLanguage?.filter(e => e);


    return ({
        initialValues: {
            question: {
                id: props.question && props.question.id ? props.question.id : 0,
                guid: props.question?.guid,
                description: existingQuestionLanguage?.description,
                displayType: props.question?.displayType ? props.question?.displayType : DisplayType.text,
                showInDropdown: props.question?.showInDropdown,
                addOwnAnswer: props.question?.addOwnAnswer,
                hasMultipleAnswers: props.question?.hasMultipleAnswers,
                rank: props.question?.rank,
                minValue: props.question?.minValue,
                maxValue: props.question?.maxValue,
                steps: props.question?.steps,
                generalDescription: props.question?.description,
                regexValidationFormat: existingQuestionLanguage ? existingQuestionLanguage.regexValidationFormat : null,
                addingInformation: existingQuestionLanguage?.addingInformation,
                type: props.question ? props.question?.questionType : QuestionType.open,
                languageId: state.selectedLanguageId,
                followUpQuestionGuid: props.question?.answers ? props.question?.answers.find(e => e)?.followUpQuestionGuid : null
            },
            answers: existingAnswersLanguage ? existingAnswersLanguage.sort((a, b) => a!.rank > b!.rank ? 1 : -1) : [],
            ownAnswer: ownValue ? ownValue : { rank: props.questions ? props.questions.length : 0, id: 0, generalDescription: "", answerText: "", score: 1, followUpQuestionGuid: null, isOwnAnswer: true },
            languageId: state.selectedLanguageId,
        } as any,
        selectedLanguageId: state.selectedLanguageId,
        i18n: state.i18n,
        profile: state.profile,
        formValues: getFormValues(formName)(state) as any
    })
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    dispatch,
    updateFormValue: (field: string, value: string) => dispatch(change(formName, field, value)),
});

const QuestionReduxForm = reduxForm<{}, Props>({
    form: formName,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
})(QuestionForm);



const conn = connect(mapStateToProps, mapDispatchToProps)(QuestionReduxForm);

export default withRouter(conn);
